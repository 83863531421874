export const config = {
  services: {
    mediquo: {
      chat_api_url: process.env.REACT_APP_MEDIQUO_CHAT_API_URL,
    },
    osigu: {
      prescription_widget_url:
        process.env.REACT_APP_OSIGU_PRESCRIPTION_SCRIPT_URL,
    },
  },
};
