const Checked = (props: any) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="current"
		viewBox="0 0 15 11"
		className="fill-current"
		{...props}
	>
		<path
			fill="current"
			d="M12.691.239L4.585 8.345 1.73 5.498A.817.817 0 10.573 6.655l3.43 3.43c.32.32.836.32 1.156 0l8.689-8.681a.817.817 0 000-1.157h-.008A.8.8 0 0012.69.24z"
		></path>
	</svg>
);
export default Checked;
