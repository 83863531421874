import PrescriptionPaper from "../assets/images/PrescriptionPaper";

const Success = () => (
  <div className="grid content-center h-screen">
    <div className="text-center flex flex-col items-center">
      <PrescriptionPaper className="mb-4" />
      <h1 className="text-primary font-medium text-2xl mb-2">
        Receta realizada con éxito
      </h1>
      <p className="text-dark">
        La receta ya se ha enviado a tu paciente y está disponible en el listado
        de recetas
      </p>
    </div>
  </div>
);

export default Success;
