import { useEffect, useState } from "react";

const useRempeListener = () => {
	const [isEvent, setIsEvent] = useState<boolean>(false);
	const [rempeEvent, setRempeEvent] = useState<any>(null);

	useEffect(() => {
		window.addEventListener(
			"message",
			(event) => {
				if (
					event.data.code !== undefined &&
					event.data.code !== null &&
					event.data.code === "REMPE_PRESCRIPTION_UPDATE"
				) {
					setRempeEvent(event.data?.code);
					setIsEvent(true);
					return;
				}

				setRempeEvent(null);
			},
			false
		);
	}, []);

	return {
		isEvent,
		setIsEvent,
		rempeEvent,
	};
};

export default useRempeListener;
