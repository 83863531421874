import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Eprescription from "./pages/eprescription";
import Success from "./pages/success";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Eprescription />} />
      <Route path="/eprescription-success" element={<Success />} />
    </Routes>
  </Router>
);

export default App;
