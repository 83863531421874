const PrescriptionPaper = (props: any) => (
  <svg
    width={344}
    height={231}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M171.868 55.945h130.903L171.868 195.752 40.966 55.945h130.902Z"
        fill="#D7BCF1"
      />
      <path
        d="M125.657 55.733c0-3.48-.698-6.926-2.055-10.141a26.479 26.479 0 0 0-5.853-8.597 27.044 27.044 0 0 0-8.759-5.745 27.446 27.446 0 0 0-10.333-2.017c-3.545 0-7.056.686-10.332 2.017a27.042 27.042 0 0 0-8.76 5.745 26.475 26.475 0 0 0-5.852 8.597 26.075 26.075 0 0 0-2.056 10.141h54Z"
        fill="#6321A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.563 55.945a26.706 26.706 0 0 0-7.825-18.89 26.701 26.701 0 0 0-18.89-7.825h127.34a26.72 26.72 0 0 1 24.682 16.491 26.725 26.725 0 0 1 2.033 10.224v115.764h-127.34V55.945Z"
        fill="#F1E4FF"
      />
      <path
        d="M141.657 68.233h94M141.657 81.233h82M141.657 92.233h89M141.657 105.233h68M141.657 130.233h68"
        stroke="#6321A8"
        strokeLinecap="round"
      />
      <path
        d="M178.657 171.733a26.494 26.494 0 0 1-16.359 24.483 26.494 26.494 0 0 1-28.879-5.745 26.494 26.494 0 0 1-7.762-18.738h53Z"
        fill="#F1E4FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M304.299 181.932a26.705 26.705 0 0 0 2.034-10.223h-26.715 26.715l-.001-10.686h-127.34v10.686a26.723 26.723 0 0 1-7.824 18.89 26.723 26.723 0 0 1-18.89 7.824h127.34a26.723 26.723 0 0 0 18.89-7.824 26.723 26.723 0 0 0 5.791-8.667Z"
        fill="#6321A8"
      />
      <circle
        cx={83.009}
        cy={124.702}
        r={26.075}
        transform="rotate(-50.6 83.009 124.702)"
        fill="#F5C4DD"
      />
      <circle
        cx={85.027}
        cy={123.089}
        r={24.372}
        transform="rotate(-50.6 85.027 123.089)"
        fill="#F1E4FF"
      />
      <g filter="url(#b)">
        <rect
          x={67.883}
          y={109.247}
          width={4.693}
          height={44.284}
          rx={2.346}
          transform="rotate(-44.592 67.883 109.247)"
          fill="#F5C4DD"
        />
      </g>
      <rect
        x={297.045}
        y={17.654}
        width={18.662}
        height={48.907}
        rx={9.331}
        transform="rotate(43.164 297.045 17.654)"
        fill="#201552"
      />
      <rect
        x={284.279}
        y={31.266}
        width={18.662}
        height={30.245}
        rx={9.331}
        transform="rotate(43.164 284.279 31.266)"
        fill="#F1E4FF"
      />
      <circle cx={226.657} cy={147.233} r={8} fill="#FFB801" />
      <path
        d="m208.694 154.233 7.215-7.311c.644-.653 1.756-.197 1.756.72s1.112 1.374 1.756.721l3.044-3.085c1.266-1.283 3.444-.731 3.947 1 .502 1.731 2.63 2.226 3.627.724.391-.59.766-1.22 1.082-1.86.753-1.524 4.322-3.285 7.527-4.57 1.799-.721 3.687.646 3.687 2.585 0 2.247 2.473 3.615 4.377 2.421l3.316-2.08a5.002 5.002 0 0 1 3.718-.651l8.184 1.778a3.026 3.026 0 0 1 2.384 2.956c0 2.597 3.407 2.729 1.366 1.123-3.746-2.947-19.143-3.393-61.023 5.529"
        stroke="#3C50EC"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.657 .233)"
          d="M0 0h343v230H0z"
        />
      </clipPath>
      <filter
        id="b"
        x={68.855}
        y={106.924}
        width={32.488}
        height={32.886}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={3} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1032_1821" />
      </filter>
    </defs>
  </svg>
);

export default PrescriptionPaper;
