import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useScript from "../utils/useScript";
import { config } from "../config";
import RempePrescriptionIframe from "./rempe";

const url = config.services.mediquo.chat_api_url;
const osigu_url = config.services.osigu.prescription_widget_url;

const getCredentials = async ({ jwt, roomId }) => {
	const response = await axios.all([
		axios.get(`${url}/professionals/v1/prescription-provider`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		}),
		axios.get(`${url}/professionals/v1/rooms/${roomId}`, {
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		}),
	]);

	const prescriptionProviders = response[0].data.data;
	const room = response[1].data.data;

	const setup = await axios.get(
		`${url}/professionals/v1/prescriptions/${room.meta.contact_id}/setup`,
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		}
	);

	const providerName = setup.data.data.provider_name;
	const credentials = prescriptionProviders.filter(
		(credential) => credential.provider === providerName
	)[0];

	return {
		setup: setup.data.data,
		credentials: credentials,
		profile: room,
	};
};

const handleOnPrescriptionComplete =
	({ setup, jwt, roomId, consultationId, consultationType }) =>
	async ({ detail }) => {
		await axios.post(
			`${url}/professionals/v1/rooms/${roomId}/prescriptions`,
			{
				data: detail,
				provider: setup.setup.provider_name,
				consultation_id: consultationId,
				consultation_type: consultationType,
			},
			{
				headers: {
					Authorization: `Bearer ${jwt}`,
				},
			}
		);

		window.location.replace("/eprescription-success");
	};

const MAX_CHARACTERS_ALLOWED = 50;

const Eprescription = () => {
	const location = useLocation();
	const params = useMemo(
		() => new URLSearchParams(location.search),
		[location]
	);
	const status = useScript(osigu_url);
	const [setup, setSetup] = useState();
	const [isRempe, setIsRempe] = useState(false);

	useEffect(() => {
		if (status !== "ready") {
			return;
		}

		getCredentials({
			jwt: params.get("jwt"),
			roomId: params.get("room_id"),
		}).then((setup) => {
			if (setup.setup?.provider === "rempe") {
				setSetup(setup);
				setIsRempe(true);
				return;
			}

			const doctor = JSON.parse(setup.credentials?.data);

			const [firstName, ...lastName] = setup.profile.title.split(" ");

			let config = {
				containerId: "osigu_root",
				clientId: setup.setup.client_id,
				clientSecret: setup.setup.client_secret,
				primaryColor: "#4A1EA7",
				errorColor: "#FF014D",
				locale: "es",
				doctor: {
					countryCode: doctor.countryCode.toUpperCase(),
					medicalLicenseNumber: doctor.medicalLicenseNumber,
				},
				patient: {
					givenNames: firstName?.slice(0, MAX_CHARACTERS_ALLOWED),
					surnames: lastName?.join(" ").slice(0, MAX_CHARACTERS_ALLOWED),
					nationality: setup.profile.meta.country_code?.toUpperCase(),
					//areaCode: countryCallingCode ? `+${countryCallingCode}` : undefined,

					dateOfBirth: setup.profile.meta.birth_date,
					emailAddress: setup.profile.meta.email,
					sex: { 0: "MALE", 1: "FEMALE" }[setup.profile.meta.gender],
				},
				template: setup.setup.template,
			};

			if (setup.credentials.countryCode?.toLowerCase() !== "es") {
				config.enabledFeatures = ["diagnoses"];
			}

			window.EPrescriptionWidget.createWidget(config);

			document.getElementById("osigu_root").addEventListener(
				"onPrescriptionComplete",
				handleOnPrescriptionComplete({
					setup,
					roomId: params.get("room_id"),
					jwt: params.get("jwt"),
					consultationId: params.get("consultation_id"),
					consultationType: params.get("consultation_type"),
				})
			);
		});
	}, [status, params]);

	if (isRempe) {
		return (
			<main>
				<div className="h-[100vh]">
					<RempePrescriptionIframe
						jwt={params.get("jwt")}
						credentials={setup.setup}
						consultationId={params.get("consultation_id")}
						consultationType={params.get("consultation_type")}
						room={setup.profile}
					/>
				</div>
			</main>
		);
	}

	if (!params.has("jwt")) {
		return (
			<Page>
				<h1 className="text-primary font-medium text-2xl">
					Página no encontrada
				</h1>
			</Page>
		);
	}

	return (
		<main>
			{status !== "ready" && (
				<Page>
					<h1 className="text-primary font-medium text-2xl">Cargando...</h1>
				</Page>
			)}
			<div id="osigu_root"></div>
		</main>
	);
};

const Page = ({ children }) => (
	<div className="grid content-center h-screen">
		<div className="flex flex-col items-center justify-center">{children}</div>
	</div>
);

export default Eprescription;
